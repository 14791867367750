import { Button, Stack, TableCell } from '@mui/material';
import { Offer } from '../../../types';
import { useCaptiveWaitList } from '../../modals/CaptiveRedirectWaitlistModalProvider';
import { useEmptyModal } from '../../modals/EmptyModalProvider';

/**
 * Component displaying the buttons used to view offer details or go to listing page (mobile)
 */

const ActionsCellMobile = ({ offer }: { offer?: Offer }) => {
  const { showCaptiveWaitList } = useCaptiveWaitList();
  const { openModal } = useEmptyModal();

  return offer ? (
    <TableCell
      sx={{
        textAlign: 'center',
        justifyItems: 'center',
        padding: 0.5,
      }}
    >
      <Stack direction={'column'}>
        {offer?.externalLink && (
          <Button
            size="small"
            sx={{
              padding: '3px 9px 3px 9px',
              margin: 'auto',
              marginBottom: 0.5,
              fontSize: 12,
              minWidth: '72px',
            }}
            variant={'outlined'}
            onClick={() => {
              showCaptiveWaitList(
                offer?.externalLink,
                undefined,
                undefined,
                offer.updatedAt
              );
            }}
          >
            Buy
          </Button>
        )}
        {/* <ClickAwayListener> */}
        <Button
          sx={{ padding: '3px 9px 3px 9px', margin: 'auto', fontSize: 12 }}
          variant={'outlined'}
          onClick={(e) => {
            openModal(
              <>
                <b>Details</b>
                <br />
                <>{offer?.description}</>
              </>
            );
          }}
        >
          Details
        </Button>
        {/* </ClickAwayListener> */}
      </Stack>
    </TableCell>
  ) : (
    <TableCell>-</TableCell>
  );
};

export default ActionsCellMobile;
