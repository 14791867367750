import { createSlice } from '@reduxjs/toolkit';
import { FetchedEvent } from '../types';

export interface EventState {
  events: EventStateType;
}

export interface EventStateType {
  allEvents: FetchedEvent[];
  currentEvents: FetchedEvent[];
}

const initialState = {
  allEvents: [] as FetchedEvent[],
  currentEvents: [] as FetchedEvent[],
};

const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    addAllEvents: (state, action: { payload: FetchedEvent[] }) => {
      state.allEvents.push(...action.payload);
      state.allEvents.sort((a, b) => {
        if (!a.startTime) {
          if (!b.startTime) {
            return 0;
          }
          return 1;
        } else if (!b.startTime) {
          return -1;
        } else if (a.startTime === b.startTime) {
          return a.id > b.id ? 1 : -1;
        }

        return a.startTime > b.startTime ? 1 : -1;
      });
    },
    setCurrentEvents: (state, action: { payload: FetchedEvent[] }) => {
      state.currentEvents = action.payload;
    },
  },
});

export const { addAllEvents, setCurrentEvents } = slice.actions;

export default slice.reducer;
