import { configureStore } from '@reduxjs/toolkit';

import eventReducer from './eventsSlice';
import offerReducer from './offerSlice';

export default configureStore({
  reducer: {
    offer: offerReducer,
    events: eventReducer,
  },
});

/**
 * Usage:
 * const dis = useDispatch();
 * dis(valueSetFunction(buttonText));
 * const state: OfferStateType = useSelector((state: any) => state.identifier);
 */
