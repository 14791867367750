import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  Tooltip,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

const VerticalEventTile = ({
  route,
  imagePath,
  cardText,
  cardTitle,
  subheader,
}: {
  route: string;
  imagePath: string;
  cardText: string;
  cardTitle: string;
  subheader: JSX.Element | string;
}) => {
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 600;

  function handleClick() {
    // TODO correct routing
    navigate(`event/${route}`);
  }

  return (
    <Tooltip
      sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
      title={cardText}
      enterDelay={800}
      enterNextDelay={800}
      arrow
    >
      <Card
        sx={{
          width: isMobile ? 150 : 300,
          height: isMobile ? 205 : 410,
          backgroundColor: 'rgb(219, 218, 215)',
          borderRadius: isMobile ? '8px' : '15px',
        }}
        className="eventTile vertical"
        onClick={handleClick}
      >
        <CardHeader
          sx={{
            color: 'white',
            backgroundColor: 'rgba(0,0,0,0.6)',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            // height: '100%',
            height: isMobile ? 60 : undefined,
          }}
          title={
            <Box
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: !isMobile ? 250 : 125,
              }}
            >
              <Typography
                sx={{ fontWeight: 'bold' }}
                variant={isMobile ? undefined : 'h5'}
                noWrap
              >
                {cardTitle}
              </Typography>
            </Box>
          }
          subheader={
            <Box
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: !isMobile ? 250 : 125,
                color: 'white',
              }}
            >
              <Typography
                sx={{ fontSize: isMobile ? '0.5em' : '0.8em' }}
                noWrap
              >
                {subheader}
              </Typography>
            </Box>
          }
        />
        <CardMedia
          sx={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            color: 'transparent',
          }}
          component="img"
          loading="lazy"
          image={imagePath}
          alt={cardTitle}
        ></CardMedia>
        {/* <CardContent sx={{ margin: 'auto', bottom: 0 }}>
        <Typography textAlign={'center'} noWrap>
          {cardText}
        </Typography>
      </CardContent> */}
      </Card>
    </Tooltip>
  );
};

export default VerticalEventTile;
