import { Chip } from '@mui/material';
import { logoMap } from '../../../../logoMap';
import { characterLimit } from '../../../../scripts/utils';

/**
 * Tag showing the logo of the seller
 */

const ListerLogoTag = ({ externalLister }: { externalLister: string }) => {
  const logoInfo = logoMap[externalLister];
  const isMobile = window.innerWidth < 600;

  return (
    <Chip
      avatar={
        <img
          src={logoInfo?.url}
          alt={externalLister + '_logo'}
          style={{
            color: 'transparent',
            backgroundColor: 'transparent',
            borderRadius: '3px',
            width: logoInfo?.addText ? undefined : logoInfo?.length,
            paddingLeft: logoInfo?.addText ? undefined : 2,
            marginRight: logoInfo?.addText ? undefined : -8,
            objectFit: 'contain',
          }}
        />
      }
      sx={{
        backgroundColor: logoInfo?.color,
        marginRight: 0.5,
        marginTop: 0.5,
        fontSize: isMobile ? 10 : 12,
      }}
      label={logoInfo?.addText ? characterLimit(externalLister ?? '', 14) : ''}
      size="small"
    />
  );
};

export default ListerLogoTag;
