import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  OfferStateType,
  setCurrentOffersWithFilters,
} from '../../store/offerSlice';
import { Offer } from '../../types';
import FuseSearchBar from '../baseLayout/FuseSearchBar';

// TODO: Use app par
const OfferSearchBar = () => {
  const state: OfferStateType = useSelector((state: any) => state.offer);
  const dis = useDispatch();

  // TODO: https://mui.com/material-ui/react-app-bar/ use this
  return (
    <Box
      sx={{
        width: '100%',
        top: 0,
        zIndex: 2,
        color: 'black',
        padding: 1.5,
        paddingBottom: 0,
      }}
    >
      <FuseSearchBar<Offer, any>
        allOptions={state.originalOffers}
        label={'Search Offers'}
        fuseKeys={['description']}
        setSearchResults={(input: Offer[]) => {
          dis(setCurrentOffersWithFilters(input));
        }}
        otherFilters={[
          state.quantityRange,
          state.currentUsers,
          state.currentTicketTypes,
        ]}
      />
    </Box>
  );
};

export default OfferSearchBar;
