import { ObjectStringKeys } from './types';

export const logoMap: ObjectStringKeys<{
  url: string;
  addText: boolean;
  length?: number | string;
  color?: string;
}> = {
  Ticketmaster: {
    addText: true,
    url: 'https://public-assets-ee.s3.us-east-2.amazonaws.com/public/external-icons/ticketmaster.webp',
  },
  'Frontgate (Ticketmaster)': {
    addText: true,
    url: 'https://public-assets-ee.s3.us-east-2.amazonaws.com/public/external-icons/frontgate.webp',
  },
  Vividseats: {
    addText: true,
    url: 'https://public-assets-ee.s3.us-east-2.amazonaws.com/public/external-icons/vividseats.png',
  },
  Seatgeek: {
    addText: false,
    url: 'https://public-assets-ee.s3.us-east-2.amazonaws.com/public/external-icons/seatgeek.jpg',
    color: 'rgb(254,91,71)',
    length: 64,
  },
  Stubhub: {
    addText: false,
    url: 'https://public-assets-ee.s3.us-east-2.amazonaws.com/public/external-icons/stubhub.png',
    length: 42,
  },
  Gametime: {
    addText: true,
    url: 'https://public-assets-ee.s3.us-east-2.amazonaws.com/public/external-icons/gametime.png',
  },
  Viagogo: {
    addText: false,
    url: 'https://public-assets-ee.s3.us-east-2.amazonaws.com/public/external-icons/viagogo.png',
    length: 72,
  },
  Tickpick: {
    addText: true,
    url: 'https://public-assets-ee.s3.us-east-2.amazonaws.com/public/external-icons/tickpick.png',
  },
  Ticketsmarter: {
    addText: false,
    url: 'https://public-assets-ee.s3.us-east-2.amazonaws.com/public/external-icons/ticketsmarter.png',
    length: 88,
  },
  Tixr: {
    addText: false,
    url: 'https://public-assets-ee.s3.us-east-2.amazonaws.com/public/external-icons/tixr.png',
    length: 64,
  },
  Seetickets: {
    addText: false,
    url: 'https://public-assets-ee.s3.us-east-2.amazonaws.com/public/external-icons/seetickets.png',
    length: 64,
    color: 'black',
  },
  Veritix: {
    addText: false,
    url: 'https://public-assets-ee.s3.us-east-2.amazonaws.com/public/external-icons/veritix.png',
    length: '82px',
  },
  Axs: {
    addText: false,
    url: 'https://public-assets-ee.s3.us-east-2.amazonaws.com/public/external-icons/axs.svg',
    length: '48px',
  },
  Showclix: {
    addText: true,
    url: '',
  },
};
