import { Cancel } from '@mui/icons-material';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';

export function SelectDropdown({
  values,
  setValues,
  options,
  label,
  defaultValue,
  maxVisibleTags = 5,
}: {
  values: string[];
  setValues: (a: string[]) => void;
  options: string[];
  label: string;
  defaultValue?: string[];
  maxVisibleTags?: number;
}) {
  const isMobile = window.innerWidth < 600;
  return (
    <Select
      multiple
      label={label}
      size={isMobile ? 'small' : 'medium'}
      value={values}
      onChange={(e) => {
        setValues(e.target.value as string[]);
      }}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.slice(0, maxVisibleTags).map((value) => {
            return <Chip key={value} label={value} />;
          })}
          {selected.length > maxVisibleTags && (
            <Chip label={`+${selected.length - maxVisibleTags}`} />
          )}
        </Box>
      )}
      // input={<OutlinedInput label={label} />}
    >
      {options.map((name) => (
        <MenuItem key={name} value={name}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
}
