function getBaseUrl() {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:8080';
  }
  return 'https://ykm7tipqpk.us-west-2.awsapprunner.com';
}

export const BASE_URL = getBaseUrl();

export const QUANTITY_UPPER_LIMIT = 6;
