import { QuestionAnswer } from '@mui/icons-material';
import InstagramIcon from '@mui/icons-material/Instagram';
import ShareIcon from '@mui/icons-material/Share';
import { Alert, Box, Grid, IconButton, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';

/**
 * The page footer that appears on the landing page, with links to social media & to contact via google form
 */

function rickRoll() {
  window.open('https://www.youtube.com/watch?v=a3Z7zEc7AXQ', '_blank');
}

function openTwitter() {
  window.open('https://twitter.com/myhandleisbest', '_blank');
}

function openGithub() {
  window.open('https://github.com/hwchase17', '_blank');
}

function openGoogleForm() {
  window.open(
    'https://docs.google.com/forms/d/e/1FAIpQLSfgCPr1rr9807sKvCAaX9_AcRLuGm1rell1OM0EkOpeSvRI6Q/viewform?usp=sf_link',
    '_blank'
  );
}

export default function ContactWaterMark() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function shareLink() {
    navigator.clipboard.writeText('https://festsearch.app/');
  }

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        bottom: 0,
        backgroundColor: 'lightorange',
      }}
    >
      <Grid container direction="row" justifyContent={'space-evenly'}>
        <Grid>
          <IconButton
            onClick={() => {
              window.open(
                'https://docs.google.com/forms/d/e/1FAIpQLSeDUt8UutySe1w_fhnOhg3QSAqwlpvfw2fCP7iAJB20SUJe1A/viewform?usp=sf_link',
                '_blank'
              );
            }}
          >
            <QuestionAnswer sx={{ fontSize: 40 }} />
          </IconButton>
        </Grid>
        {/* <Grid>
          <IconButton onClick={openTwitter}>
            <TwitterIcon sx={{ fontSize: 40 }} />
          </IconButton>
        </Grid>
        <Grid>
          <IconButton onClick={openGithub}>
            <GitHubIcon sx={{ fontSize: 40 }} />
          </IconButton>
          </Grid> */}
        <Grid>
          <IconButton onClick={rickRoll}>
            <InstagramIcon sx={{ fontSize: 40 }} />
          </IconButton>
        </Grid>
        <Grid>
          <IconButton
            onClick={() => {
              shareLink();
              handleClick();
            }}
          >
            <ShareIcon sx={{ fontSize: 40 }} />
          </IconButton>
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={2200} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: '100%', right: 10 }}
        >
          Copied site URL to clipboard!
        </Alert>
      </Snackbar>
      {/* <BingoCount /> */}
    </Box>
  );
}
