import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from '@mui/icons-material';
import { Box, IconButton, TablePagination } from '@mui/material';
import React from 'react';
import { Offer } from '../../types';

/**
 * A reusable component for pagination in tables
 */

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement>,
    num: number | null
  ) => {
    if (num == 0) {
      onPageChange(event, num);
    } else if (num == null) {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    } else {
      onPageChange(event, page + num);
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={(e) => handlePageChange(e, 0)}
        disabled={page === 0}
        aria-label="first page"
      >
        {<FirstPage />}
      </IconButton>
      <IconButton
        onClick={(e) => handlePageChange(e, -1)}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={(e) => handlePageChange(e, 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={(e) => handlePageChange(e, null)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
    </Box>
  );
}

const BaseTablePagination = ({
  asks,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
}: {
  asks: Offer[];
  rowsPerPage: number;
  setRowsPerPage: any;
  page: number;
  setPage: any;
}) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      sx={{
        borderBottom: 'none',
      }}
      rowsPerPageOptions={[
        2,
        5,
        10,
        25,
        50,
        100,
        { label: 'All', value: 1000000 },
      ]}
      colSpan={4}
      count={asks.length}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: {
          'aria-label': 'rows per page',
        },
        native: true,
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  );
};

export default BaseTablePagination;
