import { Offer } from '../types';
import { axiosPublic } from './axios';
import { BASE_URL } from '../config';
import { FetchedEvent } from '../types';
import { customCapitalize } from './utils';

/**
 *
 * @param eventId
 * @returns
 */
export async function getOffersForEventId(eventId: number): Promise<Offer[]> {
  const config = {
    method: 'get',
    url: `${BASE_URL}/api/offer?eventId=${eventId}`,
    // headers: {},
  };

  const res = await axiosPublic(config);
  const offers = res.data ?? [];

  return offers.map((offer: Offer) => {
    offer.externalLister = customCapitalize(offer.externalLister);
    if (offer.externalLister == 'Frontgate') {
      offer.externalLister = 'Frontgate (Ticketmaster)';
    }
    // else if (offer.externalLister == 'Vividseats') {
    //   offer.externalLister = 'Vivid Seats';
    // }
    offer.description = customCapitalize(offer.description);

    return offer;
  });
}

/**
 *
 * @returns
 */
export async function getAllEvents(): Promise<FetchedEvent[]> {
  const config = {
    method: 'get',
    url: `${BASE_URL}/api/events`,
    // headers: {},
  };
  return (await axiosPublic(config)).data;
}

/**
 *
 * @param id
 * @returns
 */
export async function getEvent(id: number): Promise<FetchedEvent> {
  const config = {
    method: 'get',
    url: `${BASE_URL}/api/event/${id}`,
    // headers: {},
  };
  return (await axiosPublic(config)).data;
}

export async function addEmailToWaitlist(email: string) {
  const config = {
    method: 'post',
    url: `${BASE_URL}/api/waitlist`,
    data: { email },
  };

  return await axiosPublic(config);
}

export async function getOffersForEventPageMultipart(
  eventId: number | string,
  setRefreshing: (arg0: string) => void,
  handleNewlyFetchedOffers: (offers: Offer[], src: string) => void,
  addSuccessfulFetch: (capitalizedSrc: string, status: string) => void,
  showSnackbar: (
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error'
  ) => void
): Promise<void> {
  // TODO: reg validation of numeric
  const params = new URLSearchParams({
    eventId: eventId.toString(),
  });

  try {
    const abortController = new AbortController();
    const abortTimeoutId = setTimeout(() => abortController.abort(), 30000); // 30s timeout?
    let tooSlow = false;

    // Show incomplete results if it's been 15
    const tooSlowTimeoutId = setTimeout(() => {
      setRefreshing('FLAT_MAP');
      showSnackbar(
        'Some offers took too long, they will be added when they arrive',
        'warning'
      );
      tooSlow = true;
    }, 8000); // 10s timeout?

    // Abort request entirely if it takes longer than 30s
    const response = await fetch(`${BASE_URL}/api/offer-temp?${params}`, {
      signal: abortController.signal,
    });

    const contentType = response.headers.get('content-type');

    if (contentType && contentType.includes('multipart/form-data')) {
      setRefreshing('REFRESHING');
      const boundary = contentType.split(';')[1].split('=')[1];
      const newLineBoundary = `\r\n${boundary}\r\n`;

      // Create a custom TransformStream to parse incoming chunks
      const streamTransformer = new TransformStream<BufferSource, string>({
        transform(chunk, controller) {
          try {
            const chunkStr = new TextDecoder().decode(chunk);

            //@ts-ignore buffer typing has issues
            const parts = this?._buffer
              ? //@ts-ignore buffer typing has issues
                this._buffer?.toString() + chunkStr
              : chunkStr;

            const dataParts = parts.split(newLineBoundary);
            (this as any)._buffer = dataParts.pop();

            for (const part of dataParts) {
              // assume application/json type
              try {
                if (part == 'Error') {
                  return;
                }
                const res = JSON.parse(part); // here is where the data gets its name, and is received more than once
                const key = Object.keys(res)[0];
                const offersAndStatus: // string [] |
                { offers: Offer[]; status: number } = res[key]; // docker image makes sure it's just the offers now

                const data = offersAndStatus.offers;

                let casedKey = customCapitalize(key);
                if (casedKey == 'Frontgate') {
                  casedKey = 'Frontgate (Ticketmaster)';
                }

                if (tooSlow) {
                  (data?.length ?? 0) > 0
                    ? showSnackbar(`Received data from ${casedKey}`, 'success')
                    : showSnackbar(`No offers found at ${casedKey}`, 'warning');
                }

                if (key === 'availableRetrievalData') {
                  (offersAndStatus as unknown as string[]).forEach(
                    (item: any) => {
                      let lister = customCapitalize(item.externalLister);

                      if (lister == 'Frontgate') {
                        lister = 'Frontgate (Ticketmaster)';
                      }
                      addSuccessfulFetch(customCapitalize(lister), item.status);
                    }
                  );
                } else {
                  handleFeedData(
                    data,
                    casedKey,
                    addSuccessfulFetch,
                    handleNewlyFetchedOffers
                  );
                }
              } catch (e) {
                console.error('error parsing json', e, part);
              }
            }
          } catch (e) {
            console.error('error parsing stream response');
          }
        },
        flush(controller) {
          // Handle the end of the stream here, if needed
          setRefreshing('FLAT_MAP'); // stream has ended
        },
      });

      // Connect the ReadableStream from the response to our custom TransformStream
      const transformedStream = response.body?.pipeThrough(streamTransformer);

      if (!transformedStream) {
        throw new Error('transformedStream is null');
      }

      await transformedStream.pipeTo(new WritableStream());

      clearTimeout(tooSlowTimeoutId);
      clearTimeout(abortTimeoutId);
    } else {
      throw new Error('content type is not multipart/form-data');
    }
  } catch (error) {
    console.error('Error:', error);
  }
}
/**
 * This function should update the states of the offers, users, tags etc
 * @param data
 * @param casedKey
 * @param addSuccessfulFetch
 * @param freshOffers
 * @param addNewOffers
 */
function handleFeedData(
  data: any,
  casedKey: string,
  addSuccessfulFetch: (capitalizedSrc: string, status: string) => void,
  handleNewlyFetchedOffers: (offers: Offer[], src: string) => void
) {
  if (data?.length == 0) {
    addSuccessfulFetch(casedKey, 'EMPTY');
  } else if (data?.length > 0) {
    addSuccessfulFetch(casedKey, 'SUCCESS');
    // TODO: prepcoes this should be fixed BE
    const mappedData = data.map((offer: Offer) => {
      offer.externalLister = casedKey;
      offer.description = customCapitalize(offer.description);

      return offer;
    });

    // TODO: handle each source as it arrives
    handleNewlyFetchedOffers(mappedData, casedKey);
  } else {
    addSuccessfulFetch(casedKey, 'ERROR');
  }
}
