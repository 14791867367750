import { Grid } from '@mui/material';
import OfferSearchBar from './OfferSearchBar';
import OffersDisplay from './OffersDisplay';
import SearchFilters from './SearchFilters';

/**
 * The page showing offer details
 */

const EventPage = () => {
  // maybe use row reverse here
  return (
    <Grid container>
      <Grid sx={{ height: '100%', position: 'sticky' }} item xs={12} md={3}>
        <OfferSearchBar />
        <SearchFilters />
      </Grid>
      <Grid item xs={12} md={9}>
        <OffersDisplay />
      </Grid>
    </Grid>
  );
};

export default EventPage;
