import { ClickAwayListener } from '@mui/base';
import { CalendarMonth, LocationOn } from '@mui/icons-material';
import { Box, CircularProgress, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllEvents } from '../../scripts/serverRequests';
import { formatDate } from '../../scripts/utils';
import {
  EventState,
  EventStateType,
  addAllEvents,
  setCurrentEvents,
} from '../../store/eventsSlice';
import store from '../../store/store';
import { type FetchedEvent } from '../../types';
import FuseSearchBar from '../baseLayout/FuseSearchBar';
import NavigationMenu from '../baseLayout/NavigationMenu';
import { useWaitList } from '../modals/WaitlistModalProvider';

/**
 * The search bar for the event page, has a dropdown table
 */

// TODO: Use app par
const EventPageSearchBar = ({ event }: { event: FetchedEvent }) => {
  const { showWaitList } = useWaitList();
  const eventState: EventStateType = useSelector((state: EventState) => {
    return state.events;
  });
  const dis = useDispatch();
  const navigate = useNavigate();
  const loadedEvents = useRef(false);

  function handleClear() {
    dis(setCurrentEvents([]));
  }

  useEffect(() => {
    async function loadEvents() {
      try {
        const fetchedEvents = await getAllEvents();

        dis(setCurrentEvents([]));
        dis(addAllEvents(fetchedEvents));
        // setLoadedEvents(true); // could be removed and use null vs. empty
      } catch (e) {
        // setLoadedEvents(false);
      }
    }

    if (
      !loadedEvents.current &&
      store.getState()?.events?.allEvents?.length === 0
    ) {
      loadEvents();
    }
    loadedEvents.current = true;
  }, []);
  const isMobile = window.innerWidth < 600;

  //@ts-ignore // Hacky way to get the value
  const searchQuery = document.querySelector('input')?.value;

  // TODO: https://mui.com/material-ui/react-app-bar/ use this
  return (
    <Box
      sx={{
        width: '100%',
        top: 0,
        zIndex: 2,
        backgroundSize: 'contain',
        // backgroundColor: 'purple',
        backgroundImage:
          'url(https://public-assets-ee.s3.us-east-2.amazonaws.com/public/nb_resized_90.jpg)',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'rgba(0,0,0,0.35)',
          // padding: isMobile ? 1 : 1.5,
        }}
      >
        <Grid container direction="row" justifyContent={'space-between'}>
          <Grid
            container
            item
            direction={'row'}
            justifyContent={'space-between'}
            xs={12}
            flexGrow={1}
            sx={{
              paddingBottom: 1,
              paddingTop: 1,
              // backgroundColor: orange[300],
              backgroundColor: 'rgba(0,0,0,0.5)',
            }}
          >
            <Grid item xs={1} alignContent={'center'} alignSelf={'center'}>
              <img
                loading="lazy"
                width={'40'}
                style={{
                  borderRadius: '50%',
                  cursor: 'pointer',
                  margin: 'auto',
                }}
                onClick={() => {
                  navigate('/');
                }}
                src="https://public-assets-ee.s3.us-east-2.amazonaws.com/public/compressed_icon.jpg"
                alt="Fest search icon"
              />
            </Grid>
            <Grid
              sx={{
                paddingLeft: isMobile ? 1 : undefined,
              }}
              item
              xs={10}
              flexGrow={1}
            >
              <ClickAwayListener onClickAway={handleClear}>
                <FuseSearchBar<FetchedEvent, any>
                  allOptions={eventState?.allEvents ?? []}
                  setSearchResults={(searchResults: FetchedEvent[]) =>
                    dis(setCurrentEvents(searchResults))
                  }
                  label={'Search Events'}
                  fuseKeys={['name']}
                  emptyByDefault={true}
                  otherFilters={[]}
                />
              </ClickAwayListener>
              {/* Search Results Table. If used again this should be abstracted */}
              {/* TODO: There might be a hidden bug here if the user navigates back to the home page & current events is not reset to the prequery value */}
              {searchQuery != '' && (
                <TableContainer
                  sx={{
                    position: 'absolute',
                    zIndex: 1,
                    width: 'auto',
                    padding: eventState.currentEvents.length > 0 ? 1 : 0,
                  }}
                  component={Paper}
                >
                  <Table aria-label="simple table">
                    <TableBody>
                      {eventState.currentEvents?.map((row: FetchedEvent) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                          }}
                          onClick={() => {
                            navigate('/event/' + row.id);
                            location.reload();
                          }}
                        >
                          <Box>{row.name}</Box>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
            <Grid
              xs={1}
              justifyContent={'center'}
              justifyItems={'center'}
              sx={{ margin: 'auto', textAlign: 'center', color: 'white' }}
            >
              <NavigationMenu />
            </Grid>
          </Grid>
          {event ? (
            isMobile ? (
              <EventMobileHeader event={event} />
            ) : (
              <EventHeader event={event} />
            )
          ) : (
            <CircularProgress sx={{ margin: 'auto' }} />
          )}
        </Grid>
      </Box>
    </Box>
  );
};

function EventMobileHeader({ event }: { event: FetchedEvent }) {
  const dowMap: any = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tues',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
  };

  const monthMap: any = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  };

  const startTime = new Date(event.startTime ?? '');

  // TODO make this swipeable to reveal more info
  return (
    <Grid
      sx={{
        color: 'white',
        // paddingRight: 1,
        // paddingLeft: 1,
        padding: 1,
      }}
      container
      direction={'column'}
    >
      <Box
        sx={{
          fontWeight: 'bold',
          fontSize: '2em',
          margin: 'auto',
          width: '100%',
        }}
      >
        <Grid container direction={'row'}>
          <Grid
            item
            xs={1}
            style={{
              fontSize: 10,
              textAlign: 'center',
              alignSelf: 'center',
              justifySelf: 'center',
            }}
          >
            <Box style={{ margin: 'auto' }}>
              <CalendarMonth />
            </Box>
            <Box
              sx={{
                fontSize: 10,
                textAlign: 'center',
                fontWeight: 'semibold',
              }}
            >
              {monthMap[startTime.getMonth()]} {startTime.getDate()}
            </Box>
          </Grid>
          <Grid item xs={11} sx={{ paddingLeft: 1 }}>
            {/* <Stack
            style={{
              fontSize: 8,
              textAlign: 'center',
              alignSelf: 'center',
              justifySelf: 'center',
            }}
          >
            <Box style={{ margin: 'auto' }}>
              <LocationOn />
            </Box>
            <Box
              sx={{
                fontSize: 8,
                textAlign: 'center',
              }}
            >
              Los Angeles
            </Box>
          </Stack> */}
            {event?.name}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

function EventHeader({ event }: { event: FetchedEvent }) {
  // const startTime = new Date(event.startTime ?? '');

  // const dowMap: any = {
  //   0: 'Sun',
  //   1: 'Mon',
  //   2: 'Tues',
  //   3: 'Wed',
  //   4: 'Thu',
  //   5: 'Fri',
  //   6: 'Sat',
  // };

  // const monthMap: any = {
  //   0: 'Jan',
  //   1: 'Feb',
  //   2: 'Mar',
  //   3: 'Apr',
  //   4: 'May',
  //   5: 'Jun',
  //   6: 'Jul',
  //   7: 'Aug',
  //   8: 'Sep',
  //   9: 'Oct',
  //   10: 'Nov',
  //   11: 'Dec',
  // };

  return (
    <Grid
      sx={{
        color: 'white',
        padding: 1,
      }}
      container
      direction={'column'}
    >
      <Grid
        sx={{
          whiteSpace: 'nowrap',
          textAlign: 'left',
        }}
        container
        direction={'row'}
      >
        {/* {startTime && (
          <Grid item xs={1} alignSelf={'center'}>
            <span style={{ textAlign: 'center' }}>
              <div className="text m-0 p-0">
                {monthMap[startTime?.getMonth()]}
              </div>
              <div className="font-bold m-0 p-0 text-2xl font-semibold">
                {startTime?.getDate()}
              </div>
              <div className="text-xl m-0 font-normal">
                {dowMap[startTime.getDay()]}
              </div>
            </span>
          </Grid>
        )} */}
        <Grid sx={{ fontWeight: 'bold' }} item xs={1} flexGrow={1}>
          <span style={{ fontSize: 58 }}>{event?.name}</span>
          <span style={{ fontSize: 20 }}>
            <span style={{ color: 'transparent' }}> || </span>
            <LocationOn sx={{ marginBottom: 0.5 }} />
            {event?.location?.city} {event?.location?.state},{' '}
            {event?.location?.name}
            {event?.startTime && (
              <>
                <span style={{ color: 'transparent' }}> || </span>
                <CalendarMonth sx={{ marginBottom: 0.5 }} />{' '}
                {formatDate(new Date(event?.startTime))}
                {event?.endTime && event?.endTime !== event?.startTime
                  ? ` - ${formatDate(new Date(event?.endTime))}`
                  : ''}
              </>
            )}
            <br />
          </span>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EventPageSearchBar;
