// A react modal prompting the user to join the waitlist for an event
//
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { createContext, useContext, useState } from 'react';
import { addEmailToWaitlist } from '../../scripts/serverRequests';
import { formatDateTime } from '../../scripts/utils';

interface WaitListContextActions {
  showCaptiveWaitList: (
    redirectLink: string | undefined,
    customMessage?: any,
    customTitle?: string,
    lastUpdated?: string
  ) => void;
}

interface SnackBarContextProviderProps {
  children: React.ReactNode;
}

const WaitlistContext = createContext({} as WaitListContextActions);

const CaptiveRedirectWaitlistModalProvider: React.FC<
  SnackBarContextProviderProps
> = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [customTitle, setCustomTitle] = useState('Sign up to view listings!');
  const [customMessage, setCustomMessage] = useState(
    <Box>
      Join our waitlist to see the listing and be notified when we release new
      features! After you join we won't ask again 😁.
    </Box>
  );
  const [redirectLink, setRedirectLink] = useState('');
  const [lastUpdated, setLastUpdated] = useState('');
  const isMobile = window.innerWidth < 600;

  const handleJoinWaitlist = () => {
    if (!isEmailValid(email)) {
      setEmailError(true);
    } else {
      addEmailToWaitlist(email);
      localStorage.setItem('joinedWaitlist', 'true');
      // window.open(redirectLink, '_blank');
      setShowModal(false);
      setShowDisclaimer(true);
    }
  };

  const showCaptiveWaitList = (
    redirectLink: string | undefined,
    customMessage?: any,
    customTitle?: string,
    asOf?: string
  ) => {
    if (customMessage) setCustomMessage(customMessage);
    if (customTitle) setCustomTitle(customTitle);
    if (asOf) setLastUpdated(asOf);
    if (redirectLink) setRedirectLink(redirectLink);
    if (localStorage.getItem('joinedWaitlist')) {
      setShowDisclaimer(true);
      // window.open(redirectLink, '_blank');
      setShowDisclaimer(true);
    } else {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseDisclaimer = () => {
    setShowDisclaimer(false);
  };

  const handleEmailChange = (event: any) => {
    const { value } = event.target;
    setEmail(value);
    if (emailError) setEmailError(!isEmailValid(value));
  };

  const isEmailValid = (email: string) => {
    // Simple email validation regex
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };

  return (
    <WaitlistContext.Provider value={{ showCaptiveWaitList }}>
      {children}
      <Backdrop open={showModal} />
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 0,
        }}
        open={showModal}
        onClose={handleCloseModal}
        closeAfterTransition
      >
        <Fade in={showModal}>
          <Paper
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: '40%',
              maxWidth: isMobile ? '90%' : '60%',
              padding: isMobile ? 2 : 4,
            }}
          >
            {/* <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseModal}
            >
              <Close fontSize="small" />
            </IconButton> */}
            <Typography
              variant={isMobile ? 'h4' : 'h3'}
              sx={{ textAlign: 'center' }}
            >
              {customTitle}
            </Typography>
            <Stack>
              <TextField
                variant="outlined"
                label="Email"
                size={isMobile ? 'small' : 'medium'}
                fullWidth
                margin="normal"
                type="email"
                placeholder="Enter your email address"
                required
                error={emailError}
                helperText={emailError && 'Please enter a valid email'}
                onChange={handleEmailChange}
                onSubmit={handleJoinWaitlist}
                // InputProps={{
                //   endAdornment: (

                //   ),
                // }}
              />

              <Button
                type="submit"
                disabled={emailError}
                variant="contained"
                // sx={{
                //   padding: '10px 20px',
                //   borderRadius: '5px',
                //   cursor: 'pointer',
                //   transition: 'background-color 0.3s ease',
                //   '&:hover': {
                //     backgroundColor: '#3B33C7',
                //   },
                // }}
                // sx={{ minWidth: '100px', maxWidth: '200px', margin: 'auto' }}
                onClick={handleJoinWaitlist}
              >
                View Listing
              </Button>
            </Stack>
            <Box sx={{ marginTop: 1, fontSize: isMobile ? 10 : undefined }}>
              {customMessage}
            </Box>
          </Paper>
        </Fade>
      </Modal>
      <Backdrop open={showDisclaimer} />
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 0,
        }}
        open={showDisclaimer}
        onClose={handleCloseDisclaimer}
        closeAfterTransition
      >
        <Fade in={showDisclaimer}>
          <Paper
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: '40%',
              maxWidth: isMobile ? '90%' : '60%',
              padding: isMobile ? 2 : 4,
            }}
          >
            {/* <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseModal}
            >
              <Close fontSize="small" />
            </IconButton> */}
            <Typography variant="h4" sx={{ textAlign: 'center' }}>
              Now leaving Festsearch...
            </Typography>
            <Box
              sx={{
                fontSize: '75%',
                width: '80%',
                margin: 'auto',
                textAlign: 'left',
              }}
            >
              {/* TODO: add disclaimer here for affiliate */}
              <b>Note: </b>Transactions on other sites are not guaranteed by
              Festsearch. Prices are as of last check (this listing is as of{' '}
              <i>{lastUpdated ? formatDateTime(lastUpdated) : 'page load'}</i>)
              and may no longer be available. Delivery may not be included in
              festsearch estimates.
              {/* <br /> ⚠️ This button takes you to {redirectLink}. */}
            </Box>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: 'center',
              }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{ marginTop: 1 }}
                onClick={() => {
                  window.open(redirectLink, '_blank');
                }}
              >
                Continue to Listing
              </Button>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </WaitlistContext.Provider>
  );
};

const useCaptiveWaitList = (): WaitListContextActions => {
  const context = useContext(WaitlistContext);

  if (!context) {
    throw new Error('useWaitList must be used within an useWaitListProvider');
  }

  return context;
};

export { CaptiveRedirectWaitlistModalProvider, useCaptiveWaitList };
