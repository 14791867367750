import { Autocomplete, Chip, TextField } from '@mui/material';
import { characterLimit } from '../../scripts/utils';

export function ChipAutocomplete({
  values,
  setValues,
  options,
  label,
  defaultValue,
}: {
  values: string[];
  setValues: (a: string[]) => void;
  options: string[];
  label: string;
  defaultValue?: string[];
}) {
  const isMobile = window.innerWidth < 600;

  return (
    <Autocomplete
      size={isMobile ? 'small' : 'medium'}
      multiple
      limitTags={isMobile ? 2 : 5} // TODO: change this on smaller screens
      onChange={(e, value) => {
        setValues(value);
      }}
      value={values}
      id="tags-standard"
      onClick={(e) => {
        e.stopPropagation();
      }}
      options={options}
      defaultValue={defaultValue ?? options}
      ChipProps={{ size: isMobile ? 'small' : 'medium' }}
      // getOptionLabel={(option) => characterLimit(option, 14)}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip
            sx={{ fontSize: isMobile ? '0.7em' : null }}
            {...getTagProps({ index })}
            label={characterLimit(option, 14)}
          />
        ));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          sx={{ fontSize: isMobile ? '0.4em' : null }}
        />
      )}
    />
  );
}

export default ChipAutocomplete;
