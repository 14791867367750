import { Box, Button } from '@mui/material';
import { useWaitList } from '../modals/WaitlistModalProvider';

const EmptyGridDisplay = ({ badFilter }: { badFilter: boolean }) => {
  const { showWaitList } = useWaitList();
  return (
    <Box sx={{ padding: 2 }}>
      {!badFilter ? (
        <>
          No offers yet! Feeds for this event are probably under construction
          🚧, or tickets aren't for sale yet.{' '}
          <Button
            variant="outlined"
            onClick={() => {
              showWaitList();
            }}
          >
            Get notified when it's ready!
          </Button>
        </>
      ) : (
        'One of your filters is empty!'
      )}
    </Box>
  );
};

export default EmptyGridDisplay;
