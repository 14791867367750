import { Info } from '@mui/icons-material';
import { Box, TableCell, Tooltip } from '@mui/material';
import { Offer } from '../../../types';

const QuantityCell = ({
  offer,
  isMobile,
}: {
  offer?: Offer;
  isMobile: boolean;
}) => {
  return offer ? (
    <TableCell
      sx={{
        padding: isMobile ? 0.5 : 1,
      }}
    >
      <Box
        sx={{
          fontSize: 14,
          textAlign: 'center',
        }}
      >
        {offer != null && offer != undefined ? (
          // <>
          //   {offer.externalLister}
          <Box sx={{ color: 'gray', fontSize: isMobile ? 12 : 16 }}>
            {offer.remainingCount}
            <Tooltip
              sx={{
                fontSize: '1em',
              }}
              title={
                offer.sellableQuantities
                  ? `Tickets sold in groups of ${offer.sellableQuantities.join(
                      ', '
                    )}`
                  : 'This listing is not available currently'
              }
            >
              <Info />
            </Tooltip>
          </Box>
        ) : (
          // </>
          '-'
        )}
      </Box>
    </TableCell>
  ) : (
    <TableCell>-</TableCell>
  );
};

export default QuantityCell;
