import { Box, CircularProgress, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import VerticalEventTile from '../components/VerticalEventTile';
import { formatDate } from '../scripts/utils';
import { EventState } from '../store/eventsSlice';
import { type FetchedEvent } from '../types';

function HomePageGrid() {
  //@ts-ignore
  const searchQuery = document.querySelector('input')?.value;
  const eventState = useSelector((state: EventState) => state.events);

  const eventsToMap = searchQuery
    ? eventState.currentEvents
    : eventState.allEvents;

  return (
    <Grid
      sx={{ padding: 1 }}
      container
      direction="row"
      // overflowY={'scroll'}
      justifyContent="space-evenly"
    >
      {eventState.allEvents.length > 0 ? (
        eventsToMap?.length > 0 ? (
          eventsToMap.map((event: FetchedEvent, index: number) => {
            let subheader = (
              <>
                {event.startTime != null && (
                  <>
                    {formatDate(new Date(event.startTime))}
                    {event.endTime
                      ? ` - ${formatDate(new Date(event.endTime))}`
                      : ''}
                    <br />
                  </>
                )}
                {event.location?.city} {event.location?.state},{' '}
                {event.location?.name}
              </>
            );
            // if (event.startTime != null) {
            //   subheader += `\n${formatDate(new Date(event.startTime))}`;
            // }
            // if (event.endTime != null) {
            //   subheader += ` \n- ${formatDate(new Date(event.endTime))}`;
            // }
            return (
              <Grid margin={1} key={index}>
                <VerticalEventTile
                  key={event.id}
                  route={event.id.toString()}
                  imagePath={event.verticalPhotoUrl}
                  cardText={event.details}
                  cardTitle={event.name}
                  subheader={subheader}
                />
              </Grid>
            );
          })
        ) : (
          <h1>
            {eventState.allEvents.length > 0
              ? 'No results found'
              : 'Error loading events 😢'}
          </h1>
        )
      ) : (
        <Box sx={{ height: '2000px' }}>
          {/* {TODO: HACK for bottom of page } */}
          <CircularProgress sx={{ margin: 'auto' }} />
        </Box>
      )}
    </Grid>
  );
}

export default HomePageGrid;
