import {
  ContactPage,
  Event,
  Home,
  LocalActivity,
  Menu,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { type DeliberatelyAny } from '../../types';
import { useWaitList } from '../modals/WaitlistModalProvider';

/**
 * A hardcoded base implementation of a drawer navigation menu. Upgrade is to render arbitrary menu items & dvivders
 */

export default function NavigationMenu() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { showWaitList } = useWaitList();

  const toggleDrawer = (event: DeliberatelyAny) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(!open);
  };

  return (
    <>
      <IconButton onClick={toggleDrawer} sx={{ color: 'white' }}>
        <Menu />
      </IconButton>
      <Drawer anchor={'right'} open={open} onClose={toggleDrawer}>
        <Box
          sx={{ width: 'auto' }}
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <List>
            {[
              // { text: 'Home', icon: <Home />, link: '/' },
              { text: 'Home', icon: <Home />, link: '/' },
              {
                text: 'Sell Tickets',
                icon: <LocalActivity />,
                onClick: () =>
                  showWaitList(
                    <>
                      Selling tickets is not yet supported (but we're working on
                      it 😬)
                      <br />
                      Join the waitlist and we`ll let you know when it is
                      launched 🚀!
                    </>,
                    "We're working on it!"
                  ),
              },
              {
                text: 'Add New Event',
                icon: <Event />,
                onClick: () =>
                  showWaitList(
                    <>
                      Creating events is not yet supported (but we're working on
                      it 😬).
                      <br />
                      Join the waitlist and we`ll let you know when it is
                      launched 🚀!
                    </>
                  ),
              },
              {
                text: 'Contact',
                icon: <ContactPage />,
                onClick: () =>
                  window.open(
                    'https://docs.google.com/forms/d/e/1FAIpQLSeDUt8UutySe1w_fhnOhg3QSAqwlpvfw2fCP7iAJB20SUJe1A/viewform?usp=sf_link',
                    '_blank'
                  ),
              },
            ].map((el, index) => (
              <ListItem
                key={el.text}
                disablePadding
                onClick={
                  el.onClick != null
                    ? el.onClick
                    : () => {
                        navigate(el.link);
                      }
                }
              >
                <ListItemButton>
                  <ListItemIcon>{el.icon}</ListItemIcon>
                  <ListItemText primary={el.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {/* <Divider />
          <List>
            <ListItem
              key={'Settings'}
              disablePadding
              onClick={() => alert('implement settings')}
            >
              <ListItemButton>
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary={'Settings'} />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={'Account'}
              disablePadding
              onClick={() => alert('implement account page')}
            >
              <ListItemButton>
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <ListItemText primary={'Account'} />
              </ListItemButton>
            </ListItem>
            <ListItem key={'Logout'} disablePadding onClick={() => logout()}>
              <ListItemButton>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={'Logout'} />
              </ListItemButton>
            </ListItem>
          </List> */}
        </Box>
      </Drawer>
    </>
  );
}
