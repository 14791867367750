import {
  AirportShuttle,
  Event,
  Grade,
  Hotel,
  LocalParking,
  Schedule,
  Sell,
  ShoppingCart,
  Verified,
} from '@mui/icons-material';
import { Chip, Grid, TableCell } from '@mui/material';
import {
  blue,
  green,
  orange,
  pink,
  purple,
  yellow,
} from '@mui/material/colors';
import { Offer } from '../../../types';
import ListerLogoTag from './tags/ListerLogoTag';

/**
 * The cell showing all the details of an offer populated from the fetch, or nby parsing description
 */

const TagsCell = ({
  offer,
  cheapest,
  primary,
  showMultiDay: showFetchedTags,
  isMobile,
}: {
  offer?: Offer;
  cheapest: boolean;
  primary: boolean;
  showMultiDay?: boolean;
  isMobile: boolean;
}) => {
  if (!offer) return <TableCell>-</TableCell>;
  const lowerDescription = offer.description.toLowerCase();

  return (
    <TableCell
      sx={{
        padding: isMobile ? 0.5 : 1,
      }}
    >
      <Grid container direction={'row'}>
        <Grid>
          <ListerLogoTag externalLister={offer.externalLister ?? ''} />
        </Grid>
        {cheapest && (
          <Grid>
            {/* <BaseTag
              color={green[300]}
              labelText={'Best Price'}
              Icon={<Sell />}
            /> */}
            <Chip
              sx={{
                marginTop: 0.5,
                marginRight: 0.5,
                backgroundColor: green[300],
                fontSize: isMobile ? 10 : 12,
              }}
              label={
                <>
                  Best Price <Sell sx={{ fontSize: isMobile ? 14 : 18 }} />
                </>
              }
              size="small"
            />
          </Grid>
        )}
        <Grid>
          <Chip
            sx={{
              marginTop: 0.5,
              marginRight: 0.5,
              backgroundColor: primary ? blue[300] : pink[200],
              // color: primary ? grey[900] : grey[50],
              fontSize: isMobile ? 10 : 12,
            }}
            label={
              primary ? (
                <>
                  Original Seller{' '}
                  <Verified sx={{ fontSize: isMobile ? 16 : 18 }} />
                </>
              ) : (
                <>
                  Resale <ShoppingCart sx={{ fontSize: isMobile ? 14 : 18 }} />
                </>
              )
            }
            size="small"
          />
        </Grid>

        {/\bvip\b/gi.test(lowerDescription ?? '') && (
          <Grid>
            <Chip
              sx={{
                backgroundColor: pink[300],
                marginRight: 0.5,
                marginTop: 0.5,
                fontSize: isMobile ? 10 : 12,
              }}
              label={
                <>
                  VIP <Grade sx={{ fontSize: isMobile ? 16 : 18 }} />
                </>
              }
              size="small"
            />
          </Grid>
        )}
        {offer.waitlist && (
          <Grid>
            <Chip
              sx={{
                marginTop: 0.5,
                marginRight: 0.5,
                backgroundColor: yellow[400],
                fontSize: isMobile ? 10 : 12,
              }}
              label={
                <>
                  Waitlist <Schedule sx={{ fontSize: isMobile ? 16 : 18 }} />
                </>
              }
              size="small"
            />
          </Grid>
        )}
        {showFetchedTags && (
          <>
            {offer.tags.map((tag) => (
              <Grid key={tag}>
                <Chip
                  sx={{
                    marginTop: 0.5,
                    marginRight: 0.5,
                    backgroundColor: orange[400],
                    fontSize: isMobile ? 10 : 12,
                  }}
                  label={
                    <>
                      {tag} <Event sx={{ fontSize: isMobile ? 16 : 18 }} />
                    </>
                  }
                  size="small"
                />
              </Grid>
            ))}
          </>
        )}
        {lowerDescription?.includes('camping') && (
          <Grid>
            <Chip
              sx={{
                backgroundColor: green[200],
                marginRight: 0.5,
                marginTop: 0.5,
                fontSize: isMobile ? 10 : 12,
              }}
              label={
                <>
                  Camping <Hotel sx={{ fontSize: isMobile ? 16 : 18 }} />
                </>
              }
              size="small"
            />
          </Grid>
        )}
        {lowerDescription?.includes('shuttle') && (
          <Grid>
            <Chip
              sx={{
                backgroundColor: yellow[300],
                marginRight: 0.5,
                marginTop: 0.5,
                fontSize: isMobile ? 10 : 12,
              }}
              label={
                <>
                  Shuttle{' '}
                  <AirportShuttle sx={{ fontSize: isMobile ? 16 : 20 }} />
                </>
              }
              size="small"
            />
          </Grid>
        )}
        {lowerDescription?.includes('parking') && (
          <Grid>
            <Chip
              sx={{
                backgroundColor: purple[300],
                marginRight: 0.5,
                marginTop: 0.5,
                fontSize: isMobile ? 10 : 12,
              }}
              label={
                <>
                  Parking
                  <LocalParking sx={{ fontSize: 16 }} />
                </>
              }
              size="small"
            />
          </Grid>
        )}
      </Grid>
    </TableCell>
  );
};

export default TagsCell;
