import { Box, TableCell, Tooltip } from '@mui/material';
import { formatMoney } from '../../../scripts/utils';
import { Offer } from '../../../types';
import { Info } from '@mui/icons-material';

const PriceCell = ({
  offer,
  isMobile,
}: {
  offer?: Offer;
  isMobile: boolean;
}) => {
  return offer ? (
    <TableCell
      sx={{
        textAlign: 'center',
        padding: isMobile ? 0.5 : 1,
      }}
    >
      <Box sx={{ fontSize: isMobile ? 14 : 16 }}>
        {isMobile ? 'Total: ' : 'Per Ticket: '}
        <b>{formatMoney(offer.price)}</b>
      </Box>
      <Box sx={{ fontSize: isMobile ? 10 : 12, color: 'gray' }}>
        {/* {isMobile ? 'Listing: ' : 'Before Fees: '} */}
        Before Fees: {formatMoney(offer.price - offer.fees)}
      </Box>

      {isMobile && (
        <Box sx={{ color: 'gray', fontSize: 10 }}>
          Quantity {offer.remainingCount}
          <Tooltip
            sx={{
              fontSize: '1em',
            }}
            title={
              offer.sellableQuantities
                ? `Tickets sold in groups of ${offer.sellableQuantities.join(
                    ', '
                  )}`
                : 'This listing is not available currently'
            }
          >
            <Info />
          </Tooltip>
        </Box>
      )}
    </TableCell>
  ) : (
    <TableCell>-</TableCell>
  );
};

export default PriceCell;
