import { useEffect, useRef, useState } from 'react';
import { Provider, useDispatch } from 'react-redux';
import store from '../../store/store';
import HomePageGrid from '../../pages/HomePageGrid';
import ContactWaterMark from '../baseLayout/ContactWaterMark';
import { getAllEvents } from '../../scripts/serverRequests';
import { addAllEvents, setCurrentEvents } from '../../store/eventsSlice';
import LandingPageHeader from './LandingPageHeader';

const Home = () => {
  const dis = useDispatch();
  let loadedEvents = useRef(false);

  useEffect(() => {
    async function loadEvents() {
      try {
        const fetchedEvents = await getAllEvents();

        dis(setCurrentEvents(fetchedEvents));
        dis(addAllEvents(fetchedEvents));
        // setLoadedEvents(true); // could be removed and use null vs. empty
      } catch (e) {
        // setLoadedEvents(false);
      }
    }

    if (
      !loadedEvents.current &&
      store.getState()?.events?.allEvents?.length === 0
    ) {
      loadEvents();
    }
    loadedEvents.current = true;
  }, []);

  return (
    <Provider store={store}>
      {/* <LandingPageSearchBar />
      <LandingPageBanner /> */}
      <LandingPageHeader />
      <HomePageGrid />
      <ContactWaterMark />
    </Provider>
  );
};

export default Home;
