import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import LandingPageSearchBar from './LandingPageSearchBar';

const LandingPageHeader = () => {
  const url =
    'https://public-assets-ee.s3.us-east-2.amazonaws.com/public/banner_2_compressed_75.jpg';
  const isMobile = window.innerWidth < 600;
  const theme = useTheme();

  // Define your media breakpoint(s)
  const isVerticalTiling = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        backgroundImage: `url(${url})`,
        backgroundSize: isVerticalTiling ? 'cover' : 'contain',
      }}
    >
      <Box sx={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
        <LandingPageSearchBar />
        <Box padding={1.5}>
          <Box
            sx={{
              width: '100%',
              alignSelf: 'center',
              alignItems: 'center',
              alignContent: 'center',
              paddingRight: isMobile ? 1 : 2,
              paddingLeft: isMobile ? 1 : 2,
            }}
          >
            <Typography
              variant={'h3'}
              sx={{
                fontWeight: 'bold',
                fontSize: isMobile ? '30px' : undefined,
                width: '100%',
                textAlign: 'center',
                color: 'white',
                animation:
                  'typewriter 4s steps(44) 1s 1 normal both, blinkTextCursor 500ms infinite',
              }}
            >
              The best prices. No hidden fees.
            </Typography>
            {!isMobile && (
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  color: 'white',
                  fontSize: isMobile ? '0.8m' : '1em',
                  animation:
                    'typewriter 4s steps(44) 1s 1 normal both, blinkTextCursor 500ms infinite',
                }}
              >
                We compare costs across all major platforms so you don't have
                to.
                <br />
                Prices you see are what you pay.
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPageHeader;
