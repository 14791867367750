// truncate
export function characterLimit(text: string, limit: number): string {
  return text.length > limit + 3 ? text.substring(0, limit) + '...' : text;
}

/**
 * Takes in a number and returns a string with a dollar sign and two decimal places
 * @param num
 * @returns
 */
export function formatMoney(num: number | string): string {
  num = Math.round(Number(num) * 100) / 100;
  num = num.toString();
  const splitNum = num.split('.');

  if (splitNum.length === 1) {
    return `$${num}`;
  } else {
    const decimal = splitNum[1].substring(0, 2);
    if (decimal.length === 1) {
      return `$${num}0`;
    } else if (decimal.length === 0) {
      return splitNum[0];
    } else {
      return `$${splitNum[0]}.${decimal}`;
    }
  }
}

// Print formatted date with time if the date object has time information else just date. Without second information, or years. And with padded zeros, and PM (not military time)
export function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours() % 12;
  const minute = date.getMinutes();
  const second = date.getSeconds();

  const monthMap: any = {
    1: 'Jan.',
    2: 'Feb.',
    3: 'Mar.',
    4: 'Apr.',
    5: 'May.',
    6: 'Jun.',
    7: 'Jul.',
    8: 'Aug.',
    9: 'Sep.',
    10: 'Oct.',
    11: 'Nov.',
    12: 'Dec.',
  };

  // return `${monthMap[month]} ${day} ${hour}:${
  //   minute < 10 ? '0' + minute : minute
  // } ${date.getHours() > 12 ? 'PM' : 'AM'}`;

  return `${monthMap[month]} ${day}`;
}

// Function to format date and time with hours
export function formatDateTime(date: Date | string): string {
  if (typeof date === 'string') date = new Date(date);

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours() % 12;
  const minute = date.getMinutes();
  const second = date.getSeconds();
  const tz = date.getTimezoneOffset();

  const monthMap: any = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  let hourString = hour.toString();
  if (hourString === '0') {
    hourString = '12';
  }

  return `${monthMap[month]} ${day}, ${hourString}:${
    minute < 10 ? '0' + minute : minute
  }${date.getHours() > 12 ? 'PM' : 'AM'} GMT${tz < 0 ? '-' : '+'}${Math.abs(
    tz / 60
  )}`;
}

export function customCapitalize(str?: string) {
  if (!str) {
    return '';
  }

  // If it is the first character in the string
  let result = str[0].toUpperCase();

  for (let i = 1; i < str.length; i++) {
    const prevChar = str[i - 1];
    const currChar = str[i];

    if (prevChar != prevChar.toLowerCase()) {
      result += currChar.toLowerCase();
    } else {
      result += currChar;
    }
  }

  return result;
}
