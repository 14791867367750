import { Card } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router';
import EventPageSearchBar from '../components/event/EventPageSearchBar';
import { getEvent } from '../scripts/serverRequests';
import { EventState, EventStateType } from '../store/eventsSlice';
import { FetchedEvent } from '../types';

function EventBase() {
  const { eventId } = useParams();
  const [fetchedEvent, setFetchedEvent] = useState<any>();
  const [failedFetch, setFailedFetch] = useState<boolean>(false);
  const eventsState: EventStateType = useSelector(
    (state: EventState) => state.events
  );

  useEffect(() => {
    async function fetchAndSetEvent() {
      const e = await getEvent(Number(eventId));
      setFetchedEvent(e);
      if (!e) setFailedFetch(true);
    }

    const matchInState = eventsState.allEvents.find((e: FetchedEvent) => {
      return e.id === Number(eventId);
    });

    if (matchInState) {
      setFetchedEvent(matchInState);
    } else {
      fetchAndSetEvent().catch(() => setFailedFetch(true));
    }
  }, []);

  return failedFetch ? (
    <>
      <EventPageSearchBar event={fetchedEvent} />
      <Card sx={{ fontSize: 20 }}>
        Error fetching event (or this page may not exist).
      </Card>
      <Outlet />
    </>
  ) : (
    <>
      <EventPageSearchBar event={fetchedEvent} />
      {/* <EventPageTitle event={fetchedEvent} /> */}
      <Outlet context={fetchedEvent} />
    </>
  );
}

export default EventBase;
