import { Clear, Search } from '@mui/icons-material';
import {
  ClickAwayListener,
  IconButton,
  TextField,
  debounce,
} from '@mui/material';
import Fuse from 'fuse.js';
import {
  JSXElementConstructor,
  MouseEventHandler,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useEffect,
  useState,
} from 'react';

/**
 * A base component reusing MUI TextField and fuse.js to enable search
 */

function FuseSearchBar<T, V>({
  allOptions,
  setSearchResults,
  label,
  fuseKeys,
  clearOnClickAway = false,
  emptyByDefault = false,
  otherFilters = [],
}: {
  allOptions: T[];
  setSearchResults: (results: T[], ...otherFilters: V[]) => void;
  label: string;
  fuseKeys: string[] | { name: string; weight: number }[];
  clearOnClickAway?: boolean;
  emptyByDefault?: boolean;
  otherFilters: V[];
}) {
  const [query, setQuery] = useState('');
  const [fuse, setFuse] = useState<Fuse<any>>();

  useEffect(() => {
    async function initiateFuse() {
      const fuseOptions = {
        includeScore: true,
        keys: fuseKeys,
      };

      // setSearchResults(emptyByDefault ? [] : allOptions);
      if (allOptions.length === 0) {
        console.warn('allOptions is empty');
      } else {
        setFuse(new Fuse(allOptions, fuseOptions));
      }
    }

    initiateFuse();
  }, [allOptions]); // triggered by changes to allOptions

  function handleSearch() {
    if (fuse != null) {
      const fuseResults: any[] = fuse
        .search(query)
        .map((result) => result.item);

      setSearchResults(fuseResults, ...otherFilters);
    } else {
      console.warn('fuse is not defined');
    }
  }

  function handleClear() {
    setSearchResults(emptyByDefault ? [] : allOptions, ...otherFilters);
    setQuery('');
  }

  function handleInputChange(e: any) {
    const queryIC = e.target.value;
    setQuery(queryIC);

    if (fuse === null) {
      console.warn('fuse is not defined');
    } else if (/\S/.test(queryIC)) {
      // verifies nonempty query
      let fuseResults: any[] | undefined = fuse
        ?.search(queryIC)
        ?.map((result) => result.item);

      fuseResults = fuseResults || (emptyByDefault ? [] : allOptions);

      setSearchResults(fuseResults, ...otherFilters);
    } else {
      // empty query
      setSearchResults(emptyByDefault ? [] : allOptions, ...otherFilters);
    }
  }

  return clearOnClickAway ? (
    <ClickAwayListener onClickAway={handleClear}>
      <SearchBar
        label={label}
        handleInputChange={handleInputChange}
        handleSearch={handleSearch}
        handleClear={handleClear}
      />
    </ClickAwayListener>
  ) : (
    <SearchBar
      label={label}
      handleInputChange={handleInputChange}
      handleSearch={handleSearch}
      handleClear={handleClear}
    />
  );
}

const SearchBar = (props: {
  label:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactFragment
    | ReactPortal
    | null
    | undefined;
  handleInputChange: any;
  handleSearch: MouseEventHandler<HTMLButtonElement> | undefined;
  handleClear: MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  const isMobile = window.innerWidth < 600;
  return (
    <TextField
      className="search-bar"
      sx={{
        fontSize: isMobile ? '0.8rem' : '1rem',
        borderRadius: 1,
        backgroundColor: 'white',
      }}
      size={isMobile ? 'small' : 'medium'}
      fullWidth
      id="outlined-basic"
      variant="outlined"
      label={props.label}
      onChange={debounce(props.handleInputChange, 300)}
      InputProps={{
        endAdornment: (
          <>
            <IconButton onClick={props.handleClear}>
              <Clear />
            </IconButton>
            <IconButton onClick={props.handleSearch}>
              <Search />
            </IconButton>
          </>
        ),
      }}
    />
  );
};

export default FuseSearchBar;
