import { Box, Card, CircularProgress, LinearProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getOffersForEventId,
  getOffersForEventPageMultipart,
} from '../../scripts/serverRequests';
import {
  OfferState,
  OfferStateType,
  addCurrentOffers,
  addCurrentTicketType,
  addCurrentUser,
  addOriginalOffers,
  addOriginalTicketType,
  addUserToAllUsers,
  setCurrentOffers,
  setCurrentTicketTypes,
  setCurrentUsers,
  setOriginalOffers,
} from '../../store/offerSlice';
import { type Offer } from '../../types';
import { useSnackbar } from '../formBase/snackbars/SnackbarProvider';
import EmptyGridDisplay from './EmptyGridDisplay';
import OffersFlatMap from './OffersFlatMap';
import RefreshingView from './RefreshingView';

/**
 * Currently a wrapper component for displaying
 * ((DEPRECATED PURPOSE: A component that fetches offers for an event and displays them in a bid/ask accordion))
 */

// const RefreshingView = lazy(() => import('./RefreshingView'));
// const FeedUnderConstruction = lazy(() => import('./FeedUnderConstruction'));
// const OffersFlatMap = lazy(() => import('./OffersFlatMap'));

/**
 * states managed by redux
 * @returns
 */
const OffersDisplay = () => {
  // Routes with bid/ask accordion should have event ID in the URL
  const { eventId } = useParams();
  const { showSnackbar } = useSnackbar();
  const dis = useDispatch();

  // const [categorizedOffers, setCategorizedOffers] = useState<
  //   Map<string, Offer[]>
  // >(new Map());
  const [successfulFetches, setSuccessfulFetches] = useState<{
    [key: string]: string;
  }>({});
  const [accordionState, setAccordionState] = useState<string>('LOADING');
  const state: OfferStateType = useSelector((state: OfferState) => state.offer);

  const { originalOffers, currentTicketTypes, currentUsers } = state;

  const hasCalledApi = useRef(false);

  function handleNewlyFetchedOffers(newOffers: Offer[], src: string) {
    if (newOffers.length != 0) {
      dis(addOriginalOffers(newOffers));
      dis(
        addCurrentOffers(newOffers.filter((o) => o.remainingCount > 0)) ?? []
      );

      const tagSet = new Set<string>();

      newOffers.forEach((offer: Offer) => {
        for (const tag of offer.tags) {
          tagSet.add(tag);
        }
      });

      tagSet.forEach((tag) => {
        dis(addOriginalTicketType(tag));
        dis(addCurrentTicketType(tag)); // if too slow do a local mem first then w
      });

      dis(addUserToAllUsers(src)); // TODO: pass a collection and add
      dis(addCurrentUser(src));
    }
  }

  function addSuccessfulFetches(capitalizedSrc: string, status: string) {
    successfulFetches[capitalizedSrc] = status;
    setSuccessfulFetches({ ...successfulFetches });
  }

  useEffect(() => {
    // clear the old data in redux in case user goes between pages
    dis(setCurrentOffers([]));
    dis(setOriginalOffers([]));
    dis(setCurrentTicketTypes({}));
    dis(setCurrentUsers({}));

    if (hasCalledApi.current) return;
    hasCalledApi.current = true; // TODO: other similar changes for later react

    async function fetchOffers() {
      // validate real eventId
      if (eventId && /\d+/.test(eventId)) {
        let fetchedOffers;
        try {
          if (window.location.hostname !== 'localhost') {
            // if (true) {
            fetchedOffers = await getOffersForEventPageMultipart(
              eventId,
              setAccordionState,
              handleNewlyFetchedOffers,
              addSuccessfulFetches,
              showSnackbar
            );
          } else {
            const fetchedOffers = await getOffersForEventId(Number(eventId));
            handleNewlyFetchedOffers(fetchedOffers, 'DEBUG');
          }

          setAccordionState('FLAT_MAP');
        } catch (e) {
          // TODO: only on some errors
          setAccordionState('FLAT_MAP');
        }
      }
    }

    fetchOffers();
  }, []);

  let child;
  switch (accordionState) {
    case 'LOADING':
      child = (
        <Box sx={{ padding: 2 }}>
          Finding where tickets are listed...
          <LinearProgress sx={{ margin: 'auto' }} />
        </Box>
      );
      // child = <CircularProgress sx={{ margin: 'auto' }} />;
      break;
    // case 'SHOW_BY_CATEGORY':
    //   child = <OffersByCategory categoriesMap={categorizedOffers} />;
    //   break;
    case 'FLAT_MAP':
      child = <OffersFlatMap />;
      break;
    case 'REFRESHING':
      child = <RefreshingView successfulFetches={successfulFetches} />;
      break;
    case 'EMPTY_STATE':
      return (
        <EmptyGridDisplay
          badFilter={
            originalOffers.length != 0 &&
            (Object.keys(currentTicketTypes).length === 0 ||
              Object.keys(currentUsers).length === 0)
          }
        />
      );
    default:
      child = <CircularProgress sx={{ margin: 'auto' }} />;
      break;
  }

  return (
    <Card className="m-2" raised={true}>
      {child}
    </Card>
  );
};

export default OffersDisplay;
