import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import EventPage from './components/event/EventPage';
import { SnackBarProvider } from './components/formBase/snackbars/SnackbarProvider';
import Home from './components/home/Home';
import { CaptiveRedirectWaitlistModalProvider } from './components/modals/CaptiveRedirectWaitlistModalProvider';
import { EmptyModalProvider } from './components/modals/EmptyModalProvider';
import { WaitlistModalProvider } from './components/modals/WaitlistModalProvider';
import EventBase from './pages/EventBase';
import PageBase from './pages/PageBase';
import { OfferStateType } from './store/offerSlice';
import store from './store/store';
import './styles/index.css';
import { type DeliberatelyAny } from './types';

const EventPageWrapper = () => (
  <Provider store={store}>
    <Routes>
      <Route path="/" errorElement={<div>Error!</div>} element={<EventBase />}>
        <Route path=":eventId" element={<EventPage />} />
      </Route>
    </Routes>
  </Provider>
);

const Layout = ({ children }: DeliberatelyAny) => {
  const state: OfferStateType = useSelector((state: any) => state.user);

  return (
    <Provider store={store}>
      <PageBase />
    </Provider>
  );
};

const theme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      defaultProps: {
        // The props to change the default for.
        variant: 'contained',
        color: 'success',
      },
    },
  },
});

// TODO: combine the providers
const RoutedApp = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackBarProvider>
          <WaitlistModalProvider>
            <CaptiveRedirectWaitlistModalProvider>
              <EmptyModalProvider>
                <React.StrictMode>
                  <BrowserRouter>
                    <Routes>
                      <Route
                        path="/"
                        errorElement={<div>Error!</div>}
                        element={<Layout />}
                      >
                        <Route index element={<Home />} />
                        <Route path="">
                          <Route
                            path="event/*"
                            element={<EventPageWrapper />}
                          />
                        </Route>
                      </Route>
                      <Route
                        path="*"
                        element={<div>Page Not Found! Please go back.</div>}
                      />
                    </Routes>
                  </BrowserRouter>
                </React.StrictMode>
              </EmptyModalProvider>
            </CaptiveRedirectWaitlistModalProvider>
          </WaitlistModalProvider>
        </SnackBarProvider>
      </ThemeProvider>
    </Provider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<RoutedApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals is it what is the weather
// reportWebVitals();
