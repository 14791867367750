import {
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { range } from 'lodash';
import { useState } from 'react';
import { type Offer } from '../../types';
import BaseTablePagination from '../baseLayout/BaseTablePagination';
import ActionsCellDesktop from './row-cells/ActionsCellDesktop';
import ActionsCellMobile from './row-cells/ActionsCellMobile';
import PriceCell from './row-cells/PriceCell';
import QuantityCell from './row-cells/QuantityCell';
import TagsCell from './row-cells/TagsCell';

const OffersGrid = ({ offers: asks }: { offers: Offer[] }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const isMobile = window.innerWidth < 600;

  const TableHeader = () => {
    return (
      <TableHead>
        <TableRow className="xs:text-m sm:text-2xl">
          <HeaderCell width={20} contents={'Price'} />
          <HeaderCell width={22} contents={'Tags'} />
          {!isMobile && <HeaderCell width={4} contents={'Quantity'} />}
          <HeaderCell width={10} contents={'Actions'} />
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
      <AccordionDetails sx={{ padding: isMobile ? 0.5 : undefined }}>
        <Table>
          <TableHeader />
          <TblBody
            offers={asks}
            page={page}
            isMobile={isMobile}
            rowsPerPage={rowsPerPage}
          />
          {asks.length > 5 && (
            <TableFooter>
              <TableRow>
                <BaseTablePagination
                  asks={asks}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  page={page}
                  setPage={setPage}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </AccordionDetails>
    </>
  );
};

const primarySet = new Set([
  'Frontgate (Ticketmaster)',
  'Tixr',
  'Showclix',
  'Seetickets',
]);

const TblBody = ({
  offers,
  page,
  isMobile,
  rowsPerPage,
}: {
  offers: Offer[];
  page: number;
  isMobile: boolean;
  rowsPerPage: number;
}) => {
  // TODO: this should be cleaned up and probably use memoization
  return (
    <TableBody>
      {(() => {
        const startIndex = page * rowsPerPage;
        const endIndex = Math.min(startIndex + rowsPerPage, offers.length);

        const rows = [];
        for (let i = startIndex; i < endIndex; i++) {
          const exList = offers[0]?.externalLister ?? '';

          rows.push(
            offers[0] ? (
              <TableRow className="sm:text-base" key={i}>
                <PriceCell offer={offers[0]} isMobile={isMobile} />
                <TagsCell
                  offer={offers[0]}
                  cheapest={i === startIndex}
                  primary={primarySet.has(exList)}
                  isMobile={isMobile}
                />
                {!isMobile && (
                  <QuantityCell offer={offers[0]} isMobile={isMobile} />
                )}
                {!isMobile ? (
                  <ActionsCellDesktop offer={offers[0]} />
                ) : (
                  <ActionsCellMobile offer={offers[0]} />
                )}
              </TableRow>
            ) : (
              <EmptyRow rows={4} key={i} />
            )
          );
        }

        return rows;
      })()}
    </TableBody>
  );
};

const EmptyRow = ({ rows }: { rows: number }) => {
  return (
    <TableRow>
      {range(0, rows).map(() => (
        <TableCell>-</TableCell>
      ))}
    </TableRow>
  );
};

const HeaderCell = ({ width, contents }: { width: number; contents: any }) => {
  const isMobile = window.innerWidth < 600;

  return (
    <TableCell
      className="table-header"
      sx={{
        width: `${width}%`,
        // borderRight: '1px solid lightgrey',
        padding: isMobile ? '4px' : '12px',
      }}
    >
      {contents}
    </TableCell>
  );
};

export default OffersGrid;
