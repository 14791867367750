import { CheckCircle, Warning } from '@mui/icons-material';
import { Box, Card, Grid, LinearProgress, List } from '@mui/material';
import { logoMap } from '../../logoMap';
import { characterLimit } from '../../scripts/utils';

function LoadingRow({ fetchRes }: { fetchRes: string | undefined }) {
  if (!fetchRes) return <LinearProgress />;
  if (fetchRes == 'SUCCESS') return <CheckCircle sx={{ color: 'green' }} />;
  if (fetchRes == 'EMPTY') {
    return (
      <>
        <Warning sx={{ color: 'orange' }} />
        (Empty)
      </>
    );
  }
  return (
    <>
      <Warning sx={{ color: 'orange' }} />
      (Error)
    </>
  );
}

const RefreshingView = ({
  successfulFetches,
}: {
  successfulFetches: { [key: string]: string };
}) => {
  return (
    <Box sx={{ padding: 2 }}>
      Fetching latest ticket prices...
      <List>
        {Array.from(Object.keys(successfulFetches))
          .sort((k) => {
            return successfulFetches[k] == 'SUCCESS' ? -1 : 1; // some minor opp to not repeat the Map.get
          })
          .map((lister, index) => {
            const fetchRes: string = successfulFetches[lister];
            const logoInfo = logoMap[lister];

            return (
              <Card key={index} sx={{ margin: 0.5, padding: 1 }} elevation={2}>
                <Grid container>
                  <Grid xs={8} container direction={'row'}>
                    {logoInfo.addText ? characterLimit(lister, 14) : ''}
                    <img
                      src={logoInfo?.url}
                      alt={lister + '_logo'}
                      style={{
                        color: 'transparent',
                        backgroundColor: 'transparent',
                        borderRadius: '3px',
                        width: '60px',
                        height: logoInfo.addText ? '20px' : undefined,
                        paddingLeft: logoInfo?.addText ? undefined : 2,
                        marginRight: logoInfo?.addText ? undefined : -8,
                        objectFit: 'contain',
                      }}
                    />
                  </Grid>
                  <Grid xs={4} item alignSelf={'center'}>
                    <LoadingRow fetchRes={fetchRes} />
                  </Grid>
                </Grid>
              </Card>
            );
          })}
      </List>
    </Box>
  );
};

export default RefreshingView;
