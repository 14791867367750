import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EventState, setCurrentEvents } from '../../store/eventsSlice';
import { FetchedEvent } from '../../types';
import FuseSearchBar from '../baseLayout/FuseSearchBar';
import NavigationMenu from '../baseLayout/NavigationMenu';

const LandingPageSearchBar = () => {
  const navigate = useNavigate();
  const eventState = useSelector((state: EventState) => state.events);
  const dis = useDispatch();

  // TODO: https://mui.com/material-ui/react-app-bar/ use this
  return (
    <Box
      sx={{
        maxWidth: '100%',
        top: 0,
        padding: 1,
        zIndex: 2,
        backgroundColor: 'rgba(0,0,0,0.6)',
        color: 'black',
      }}
    >
      <Grid container direction="row" justifyContent={'space-between'}>
        <Grid
          item
          xs={1}
          alignSelf={'center'}
          onClick={() => {
            navigate('/');
          }}
        >
          <img
            loading="lazy"
            width={'40'}
            style={{ borderRadius: '50%', cursor: 'pointer' }}
            src="https://public-assets-ee.s3.us-east-2.amazonaws.com/public/compressed_icon.jpg"
            alt="Fest search icon"
          />
        </Grid>
        <Grid item xs={10} flexGrow={1} sx={{ paddingLeft: '4px' }}>
          <FuseSearchBar<FetchedEvent, any>
            allOptions={eventState.allEvents}
            setSearchResults={(results: FetchedEvent[]) => {
              dis(setCurrentEvents(results));
            }}
            label={'Search Events'}
            // fuseKeys={[
            //   { name: 'name', weight: 0.8 },
            //   { name: 'location.name', weight: 0.2 },
            // ]}
            fuseKeys={['name']}
            otherFilters={[]}
          />
        </Grid>
        <Grid
          item
          xs={1}
          alignSelf={'center'}
          textAlign={'right'}
          sx={{ color: 'white' }}
        >
          <NavigationMenu />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingPageSearchBar;
