import { Button, Stack, TableCell, Tooltip } from '@mui/material';
import { Offer } from '../../../types';
import { useCaptiveWaitList } from '../../modals/CaptiveRedirectWaitlistModalProvider';
import { useEmptyModal } from '../../modals/EmptyModalProvider';

/**
 * Component displaying the buttons used to view offer details or go to listing page
 */

const ActionsCellDesktop = ({ offer }: { offer?: Offer }) => {
  const { showCaptiveWaitList } = useCaptiveWaitList();
  const { openModal } = useEmptyModal();

  return offer ? (
    <TableCell
      sx={{
        textAlign: 'center',
        justifyItems: 'center',
      }}
    >
      <Stack direction={'row'}>
        <Button
          size="small"
          sx={{
            // fontSize: 10,
            padding: '3px 9px 3px 9px',
            margin: 'auto',
          }}
          variant={'outlined'}
          onClick={() => {
            showCaptiveWaitList(
              offer?.externalLink,
              undefined,
              undefined,
              offer.updatedAt
            );
          }}
        >
          See Listing
        </Button>
        <Tooltip
          sx={{
            fontSize: '1em',
          }}
          title={offer?.description}
        >
          <Button
            size="small"
            sx={{
              margin: 'auto',
              padding: '3px 9px 3px 9px',
            }}
            variant="outlined"
            onClick={() => {
              openModal(
                <>
                  <b>Details</b>
                  <br />
                  <>{offer?.description}</>
                </>
              );
            }}
          >
            Details
          </Button>
        </Tooltip>
      </Stack>
    </TableCell>
  ) : (
    <TableCell>-</TableCell>
  );
};

export default ActionsCellDesktop;
