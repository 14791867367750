import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { OfferStateType } from '../../store/offerSlice';
import OffersGrid from './OffersGrid';

const OffersFlatMap = () => {
  const state = useSelector((state: { offer: OfferStateType }) => state.offer);

  return state.currentOffers.length > 0 ? (
    <OffersGrid offers={state.currentOffers} />
  ) : (
    <Box sx={{ padding: 1 }}>
      No match for your filters, or something has gone wrong... 😬
    </Box>
  );
};

export default OffersFlatMap;
