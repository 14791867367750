// ModalContext.tsx
import { Close } from '@mui/icons-material';
import { Box, ClickAwayListener } from '@mui/material';
import React, { createContext, useState, useContext, ReactNode } from 'react';

type ModalContent = ReactNode;

interface ModalContextActions {
  openModal: (component: ModalContent) => void;
  closeModal: () => void;
  isModalOpen: boolean;
}

interface ModalProviderProps {
  children: React.ReactNode;
}

const ModalContext = createContext({} as ModalContextActions);

export const EmptyModalProvider: React.FC<ModalProviderProps> = ({
  children,
}) => {
  const [modalContent, setModalContent] = useState<ModalContent | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = (component: ModalContent) => {
    setModalContent(component);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalContent(null);
    setIsModalOpen(false);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal, isModalOpen }}>
      <Box>
        {children}
        {isModalOpen && (
          <div
            onClick={() => {
              closeModal();
              console.log('click away');
            }}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 9,
            }}
          >
            <div
              style={{
                background: '#fff',
                padding: '20px',
                borderRadius: '8px',
                maxWidth: '80%',
              }}
            >
              {modalContent}
            </div>
          </div>
        )}
      </Box>
    </ModalContext.Provider>
  );
};

export const useEmptyModal = (): ModalContextActions =>
  useContext(ModalContext);
