import { Alert, type AlertColor, IconButton, Snackbar } from '@mui/material';
import { Close } from '@mui/icons-material';
import React, { createContext, useContext } from 'react';

interface SnackBarContextActions {
  showSnackbar: (
    text: string,
    typeColor: AlertColor,
    horizontalPosition?: string,
    verticalPosition?: string
  ) => void;
}

const SnackBarContext = createContext({} as SnackBarContextActions);

interface SnackBarContextProviderProps {
  children: React.ReactNode;
}

const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [horizontalPosition, setHorizontalPosition] = React.useState('left');
  const [verticalPosition, setVerticalPosition] = React.useState('bottom');
  const [typeColor, setTypeColor] = React.useState<AlertColor>('info');

  const showSnackbar = (
    text: string,
    color: AlertColor,
    horizontalPosition = 'left',
    verticalPosition = 'bottom'
  ) => {
    setMessage(text);
    setTypeColor(color);
    setHorizontalPosition(horizontalPosition);
    setVerticalPosition(verticalPosition);
    setOpen(true);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason != 'clickaway') {
      setOpen(false);
      setTypeColor('info');
    }
  };

  return (
    <SnackBarContext.Provider value={{ showSnackbar }}>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        anchorOrigin={{
          vertical: verticalPosition as any,
          horizontal: horizontalPosition as any,
        }}
        onClose={handleClose}
      >
        <Alert severity={typeColor}>
          {message}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <Close fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>
      {children}
    </SnackBarContext.Provider>
  );
};

const useSnackbar = (): SnackBarContextActions => {
  const context = useContext(SnackBarContext);

  if (!context) {
    throw new Error('useSnackBar must be used within SnackBarProvider');
  }

  return context;
};

export { SnackBarProvider, useSnackbar };
