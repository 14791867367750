import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  Slider,
  Stack,
  Box,
  debounce,
} from '@mui/material';
import React from 'react';
import ChipAutocomplete from '../formBase/ChipAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import {
  OfferStateType,
  setCurrentTicketTypes,
  setQuantityRange,
  setCurrentUsers,
  setCurrentOffersWithFilters,
} from '../../store/offerSlice';
import { SelectDropdown } from '../formBase/SelectDropdown';

const SearchFilters = () => {
  return (
    <Card className="m-2 p-2" raised={true}>
      <Grid container justifyContent={'space-around'}>
        <DaysFilter />
        <SellersFilter />
        <QuantityFilter />
      </Grid>
    </Card>
  );
};

function DaysFilter() {
  const isMobile = window.innerWidth < 600;
  const dis = useDispatch();
  const state: OfferStateType = useSelector((state: any) => state.offer);

  return (
    <Grid>
      {isMobile ? (
        <FormControl sx={{ m: 1, maxWidth: '90%', minWidth: '60%' }}>
          <InputLabel>Days</InputLabel>
          <SelectDropdown
            values={Object.keys(state.currentTicketTypes).sort()}
            options={Object.keys(state.originalTicketTypes).sort()}
            setValues={(v) => {
              dis(
                setCurrentTicketTypes(
                  v.reduce((acc: any, curr) => ((acc[curr] = true), acc), {})
                )
              );
              dis(setCurrentOffersWithFilters());
            }}
            label="Days"
          />
        </FormControl>
      ) : (
        <FormControl sx={{ m: 1, maxWidth: '30em' }}>
          <ChipAutocomplete
            values={Object.keys(state.currentTicketTypes).sort()}
            options={Object.keys(state.originalTicketTypes).sort()}
            setValues={(v) => {
              dis(
                setCurrentTicketTypes(
                  v.reduce((acc: any, curr) => ((acc[curr] = true), acc), {})
                )
              );
              dis(setCurrentOffersWithFilters());
            }}
            label="Days"
          />
        </FormControl>
      )}
    </Grid>
  );
}

function QuantityFilter() {
  const isMobile = window.innerWidth < 600;
  const dis = useDispatch();
  const state: OfferStateType = useSelector((state: any) => state.offer);

  return (
    <Grid item>
      <Stack>
        <Box
          sx={{
            textAlign: 'center',
            marginBottom: isMobile ? -2 : -1,
          }}
        >
          Quantity
        </Box>
        <Slider
          onChange={(e, newValue) => {
            dis(setQuantityRange(newValue as number[]));
            debounce(() => dis(setCurrentOffersWithFilters()), 200);
          }}
          value={state.quantityRange}
          valueLabelDisplay="auto"
          sx={{
            minWidth: '10em',
            maxWidth: '40em',
            marginBottom: 1,
            color: '#52af77',
            height: 8,
            '& .MuiSlider-track': {
              border: 'none',
            },
            '& .MuiSlider-thumb': {
              height: 24,
              width: 24,
              backgroundColor: '#fff',
              border: '2px solid currentColor',
              '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
              },
              '&:before': {
                display: 'none',
              },
            },
            '& .MuiSlider-valueLabel': {
              lineHeight: 1.2,
              fontSize: 12,
              background: 'unset',
              padding: 0,
              width: 32,
              height: 32,
              borderRadius: '50% 50% 50% 0',
              backgroundColor: '#52af77',
              transformOrigin: 'bottom left',
              transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
              '&:before': { display: 'none' },
              '&.MuiSlider-valueLabelOpen': {
                transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
              },
              '& > *': {
                transform: 'rotate(45deg)',
              },
            },
          }}
          min={1}
          max={6}
          size="small"
          marks={[
            { value: 1, label: '1' },
            // { value: 2 },
            // { value: 3 },
            // { value: 4 },
            // { value: 5 },
            { value: 6, label: '6+' },
          ]}
        />
      </Stack>
    </Grid>
  );
}

function SellersFilter() {
  const isMobile = window.innerWidth < 600;
  const dis = useDispatch();
  const state: OfferStateType = useSelector((state: any) => state.offer);

  return (
    <Grid>
      {isMobile ? (
        <FormControl sx={{ m: 1, maxWidth: '30em', minWidth: '16em' }}>
          <InputLabel>Sellers</InputLabel>
          <SelectDropdown
            values={Object.keys(state.currentUsers).sort()}
            setValues={(v) => {
              dis(
                setCurrentUsers(
                  v.reduce((acc: any, curr) => ((acc[curr] = true), acc), {})
                )
              );
              dis(setCurrentOffersWithFilters());
            }}
            options={Object.keys(state.allUsers)}
            label="Sellers"
            defaultValue={Object.keys(state.allUsers)
              .filter((user: string) => !['EBAY', 'FACEBOOK'].includes(user))
              .sort()}
            //   .filter(
            //   (user: string) => !['EBAY', 'FACEBOOK'].includes(user)
            // )}
          />
        </FormControl>
      ) : (
        <FormControl sx={{ m: 1, maxWidth: '30em', minWidth: '16em' }}>
          <ChipAutocomplete
            values={Object.keys(state.currentUsers).sort()}
            setValues={(v) => {
              dis(
                setCurrentUsers(
                  v.reduce((acc: any, curr) => ((acc[curr] = true), acc), {})
                )
              );
              dis(setCurrentOffersWithFilters());
            }}
            options={Object.keys(state.allUsers)}
            label="Sellers"
            defaultValue={Object.keys(state.allUsers)
              .filter((user: string) => !['EBAY', 'FACEBOOK'].includes(user))
              .sort()}
            //   .filter(
            //   (user: string) => !['EBAY', 'FACEBOOK'].includes(user)
            // )}
          />
        </FormControl>
      )}
    </Grid>
  );
}

export default SearchFilters;
