import { createSlice } from '@reduxjs/toolkit';
import { QUANTITY_UPPER_LIMIT } from '../config';
import { ObjectStringKeys, Offer } from '../types';

export interface OfferState {
  offer: OfferStateType;
}

export interface OfferStateType {
  showByCategory: boolean;
  currentOffers: Offer[];
  originalOffers: Offer[];
  currentTicketTypes: ObjectStringKeys<boolean>;
  originalTicketTypes: ObjectStringKeys<boolean>;
  allUsers: ObjectStringKeys<boolean>;
  currentUsers: ObjectStringKeys<boolean>;
  quantityRange: number[];
}

const initialState: OfferStateType = {
  showByCategory: false,
  currentOffers: [] as Offer[],
  originalOffers: [] as Offer[],
  currentTicketTypes: {},
  originalTicketTypes: {},
  allUsers: {},
  currentUsers: {},
  quantityRange: [1, QUANTITY_UPPER_LIMIT] as number[],
};

const slice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    setOriginalOffers: (state, action: { payload: Offer[] }) => {
      state.originalOffers = action.payload;
    },
    addOriginalOffers: (state, action: { payload: Offer[] }) => {
      // filter out offers with quantity 0 for now
      state.originalOffers.push(
        ...action.payload.filter((o) => o.remainingCount != 0)
      );
    },
    addOriginalTicketType: (state, action: { payload: string }) => {
      state.originalTicketTypes[action.payload] = true;
    },
    setCurrentTicketTypes: (
      state,
      action: { payload: ObjectStringKeys<boolean> }
    ) => {
      state.currentTicketTypes = action.payload;
    },
    addCurrentTicketType: (state, action: { payload: string }) => {
      if (state.currentTicketTypes[action.payload] == undefined) {
        state.currentTicketTypes[action.payload] = true;
      }
    },
    removeCurrentTicketType: (state, action: { payload: string }) => {
      delete state.currentTicketTypes['action.payload'];
    },
    setCurrentUsers: (
      state,
      action: { payload: ObjectStringKeys<boolean> }
    ) => {
      state.currentUsers = action.payload;
    },
    addCurrentUser: (state, action: { payload: string }) => {
      if (state.currentUsers[action.payload] == undefined) {
        state.currentUsers[action.payload] = true;
      }
    },
    setAllUsers: (state, action: { payload: ObjectStringKeys<boolean> }) => {
      state.allUsers = action.payload;
    },
    addUserToAllUsers: (state, action: { payload: string }) => {
      state.allUsers[action.payload] = true;
    },
    setCurrentOffers: (state, action: { payload: Offer[] }) => {
      state.currentOffers = action.payload;
    },
    addCurrentOffers: (state, action: { payload: Offer[] }) => {
      state.currentOffers.push(...action.payload);
      state.currentOffers.sort((a, b) => (a.price > b.price ? 1 : -1));
    },
    setShowByCategory: (state, action: { payload: boolean }) => {
      state.showByCategory = action.payload;
    },
    setQuantityRange: (state, action: { payload: number[] }) => {
      state.quantityRange = action.payload;
    },
    setCurrentOffersWithFilters: (
      state,
      action: { payload: Offer[] | undefined }
    ) => {
      const passedOffers = action.payload
        ? action.payload
        : state.originalOffers;
      const userSelected = Object.keys(state.currentUsers).length > 0;
      const ticketTypeSelected =
        Object.keys(state.currentTicketTypes).length > 0;

      const [lower, upper] = state.quantityRange;

      state.currentOffers = passedOffers
        .filter((offer: Offer) => {
          if (
            userSelected &&
            (!offer.externalLister || !state.currentUsers[offer.externalLister])
          ) {
            return false;
          }

          // Check if
          if (
            ticketTypeSelected &&
            !offer.tags.find((tag) => state.currentTicketTypes[tag])
          ) {
            return false;
          }

          // coalesce to remainingCount in case there is no sq
          for (const q of offer.sellableQuantities ?? [offer.remainingCount]) {
            if (q >= lower && (q <= upper || upper === QUANTITY_UPPER_LIMIT)) {
              return true;
            }
          }

          return false;
        })
        .sort((a: Offer, b: Offer) => (b.price > a.price ? -1 : 1));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addOriginalOffers,
  addOriginalTicketType,
  setCurrentUsers,
  setAllUsers,
  addUserToAllUsers,
  addCurrentUser,
  setCurrentOffers,
  addCurrentOffers,
  setCurrentTicketTypes,
  addCurrentTicketType,
  removeCurrentTicketType,
  setQuantityRange,
  setShowByCategory,
  setCurrentOffersWithFilters,
  setOriginalOffers,
} = slice.actions;

export default slice.reducer;
