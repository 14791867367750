// A react modal prompting the user to join the waitlist for an event
//
import {
  Backdrop,
  Button,
  Fade,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { createContext, useContext, useState } from 'react';
import { addEmailToWaitlist } from '../../scripts/serverRequests';
interface WaitListContextActions {
  showWaitList: (
    customMessage?: JSX.Element | string,
    customTitle?: string
  ) => void;
}

interface SnackBarContextProviderProps {
  children: React.ReactNode;
}

const WaitlistContext = createContext({} as WaitListContextActions);

const WaitlistModalProvider: React.FC<SnackBarContextProviderProps> = ({
  children,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [customTitle, setCustomTitle] = useState('Be first to know!');
  const [customMessage, setCustomMessage] = useState<JSX.Element | string>(
    'We are actively adding new features & feeds! Sign up to be notified when they are ready!'
  );
  const isMobile = window.innerWidth < 600;

  const handleJoinWaitlist = () => {
    if (!isEmailValid(email)) {
      setEmailError(true);
    } else {
      addEmailToWaitlist(email);
      localStorage.setItem('joinedWaitlist', 'true');
      setShowModal(false);
    }
  };

  const showWaitList = (
    customMessage?: string | JSX.Element,
    customTitle?: string
  ) => {
    if (customMessage) setCustomMessage(customMessage);
    if (customTitle) setCustomTitle(customTitle);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleEmailChange = (event: any) => {
    const { value } = event.target;
    setEmail(value);
    if (emailError) setEmailError(!isEmailValid(value));
  };

  const isEmailValid = (email: string) => {
    // Simple email validation regex
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };

  return (
    <WaitlistContext.Provider value={{ showWaitList }}>
      {children}
      <Backdrop open={showModal} />
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 0,
        }}
        open={showModal}
        onClose={handleCloseModal}
        closeAfterTransition
      >
        <Fade in={showModal}>
          <Paper
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              // boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
              minWidth: '40%',
              maxWidth: isMobile ? '90%' : '60%',
              padding: 4,
            }}
          >
            {/* <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseModal}
            >
              <Close fontSize="small" />
            </IconButton> */}
            <Typography
              variant={isMobile ? 'h5' : 'h3'}
              sx={{ textAlign: 'center' }}
            >
              {customTitle}
            </Typography>
            <Stack>
              <TextField
                variant="outlined"
                label="Email"
                // fullWidth
                sx={{ width: '80%', margin: 'auto' }}
                size={isMobile ? 'small' : 'medium'}
                margin="normal"
                type="email"
                placeholder="Enter your email address"
                required
                error={emailError}
                helperText={emailError && 'Please enter a valid email'}
                onChange={handleEmailChange}
                onSubmit={handleJoinWaitlist}
                // InputProps={{
                //   endAdornment: (

                //   ),
                // }}
              />

              <Button
                type="submit"
                variant="contained"
                sx={{
                  maxWidth: '200px',
                  margin: 'auto',
                  marginTop: 1,
                  fontSize: isMobile ? 10 : 14,
                }}
                // sx={{
                //   padding: '10px 20px',
                //   borderRadius: '5px',
                //   cursor: 'pointer',
                //   transition: 'background-color 0.3s ease',
                //   '&:hover': {
                //     backgroundColor: '#3B33C7',
                //   },
                // }}
                // sx={{ minWidth: '100px', maxWidth: '200px', margin: 'auto' }}
                onClick={handleJoinWaitlist}
              >
                Get Updates
              </Button>
            </Stack>
            <Typography sx={{ marginTop: 1, fontSize: isMobile ? 10 : 12 }}>
              {customMessage}
            </Typography>
          </Paper>
        </Fade>
      </Modal>
    </WaitlistContext.Provider>
  );
};

const useWaitList = (): WaitListContextActions => {
  const context = useContext(WaitlistContext);

  if (!context) {
    throw new Error(
      'useWaitList must be used within an WiauseWaitListProvider'
    );
  }

  return context;
};

export { WaitlistModalProvider, useWaitList };
