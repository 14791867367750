import { Outlet } from 'react-router';
import { Box } from '@mui/material';

const PageBase = () => {
  return (
    <Box sx={{ backgroundColor: 'beige' }}>
      <Outlet />
    </Box>
  );
};

export default PageBase;
